<template>
  <BasePage :loading="loading">
    <Container v-if="settingsGetter && partners">
      <div class="text-center">
        <h2 class="primary--text">{{ $t('aboutUs') }}</h2>
        <div class="body-1 primary--text mx-auto " style="width: 60%">
          {{
            settingsGetter[$root.lang == 'ar' ? 'who_are_we' : 'enwho_are_we']
          }}
        </div>
        <h2 class="mt-8 primary--text">{{ $t('ourMsg') }}</h2>
        <div class="body-1 primary--text mx-auto " style="width: 80%">
          {{ settingsGetter[$root.lang == 'ar' ? 'message' : 'enmessage'] }}
        </div>

        <h2 class="primary--text mt-7">{{ $t('successPartners') }}</h2>

        <div class="white pa-4 my-10">
          <Slider :partners="partners" />
        </div>
      </div>
    </Container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
import { mapGetters } from 'vuex';
export default {
  components: {
    Slider: () => import('@/components/Slider'),
  },
  data() {
    return {
      loading: false,
      aboutInfo: null,
      partners: null,
    };
  },
  methods: {
    async getAboutInfo() {
      try {
        this.loading = true;
        // const { data } = await ApiService.get(`items/setting`);
        const partners = await ApiService.get(
          `items/partners?fields=*,logo.data.full_url`
        );

        // this.aboutInfo = data.data[0];
        this.partners = partners.data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters(['settingsGetter']),
  },
  mounted() {
    this.getAboutInfo();
  },
};
</script>

<style>
.shadow {
  box-shadow: 0px 2px 8px #b2b5bb;
  /* border-radius: 12px; */
  /* border: 1px solid #ddd; */
  /* overflow: hidden; */
  /* width: 80%; */
  /* transition: all 0.3s ease-in-out; */
  /* background: #ccb1de !important; */
  /*background: -webkit-linear-gradient(top left, #ccb1de, #89a6c6) !important;
  background: -moz-linear-gradient(top left, #ccb1de, #89a6c6) !important;
  background: linear-gradient(to bottom right, #ccb1de, #89a6c6) !important; */
}
</style>
